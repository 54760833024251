import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import pf from '../assets/images/pf.jpg'

const About = props => (
  <Layout>
    <Helmet>
      <title>Experian</title>
      <meta name="description" content="Experian" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <section class="overview">
            <div className="grid-wrapper">
              <div className="col-8">
                <p>
                  Hi there! I'm Matt — a digital product designer based in
                  Southern California currently @ Experian.
                </p>
                <h6>How I started my design journey</h6>
                <p>
                  My first job was at an optometry clinic before transitioning
                  into tech. Integrating my experience in biological science,
                  healthcare, and digital marketing, I am able to apply these
                  skills towards the ux process, solve problems through design
                  and supported by analytical data. My passion is to create
                  digital products that people actually want to use.
                </p>
                <h6>What I'd like to learn more</h6>
                <p>
                  I'm improving my prototyping skills and learning Native
                  design. For web development, I'm learning the MERN stack with
                  an interest in creating design systems, bridging the gap
                  between design and dev.
                </p>
                <h6>A quote that resonates with me</h6>
                <p>Diagnose with data. Treat with design.</p>
                <h6>Stuff I like to do</h6>
                <p>
                  When I'm not designing in Figma, my interests include card
                  games, photography, learning languages, hanging out with my
                  cat Meeko, traveling, and cafe hopping for matcha. Take a peek
                  of my life at&nbsp;
                  <a href="http://www.instagram.com/recoilmatt">@recoilmatt.</a>
                </p>
                <h6>Last played</h6>
                <div style={{ width: '200px' }} class="spotifyImageContainer">
                  <img
                    style={{ width: '200px' }}
                    src="https://artwork-cdn.7static.com/static/img/sleeveart/00/332/635/0033263502_350.jpg"
                    alt="spotify"
                  ></img>
                </div>
                <a
                  href="https://open.spotify.com/track/5LWWAw887ODAUvR2LR1kDg?si=124ae177840a47a9"
                  target="_blank"
                  rel="noreferrer"
                >
                  Don't Go Home
                </a>
                <br />
                DPR IAN
              </div>
              <div className="col-4">
                <span className="image main">
                  <img src={pf} alt="pf"></img>
                </span>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  </Layout>
)

export default About
